import { useRuntimeConfig } from "nuxt/app";

//ex for FR : +331444334
export function useFormattedPhone(prefix, initialNumber) {
  const noSpaceNumber = initialNumber.replaceAll(/\s/g, "");
  const finalNumber =
    noSpaceNumber[0] === "0" ? noSpaceNumber.substring(1) : noSpaceNumber;
  const result = `+${prefix}${finalNumber}`;
  return result;
}

//ex for FR : 25/06/2023
export function useFormattedDate(initialDate) {
  const config = useRuntimeConfig();
  const date = new Date(initialDate.replace(/-/g, "/"));
  return new Intl.DateTimeFormat(config.public["timeZone"]?.toString()).format(
    date
  );
}

//ex for FR : 18 juillet 2023
export function useFormattedLongDate(initialDate) {
  const config = useRuntimeConfig();
  const date = new Date(initialDate);
  return new Intl.DateTimeFormat(config.public["timeZone"]?.toString(), {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
}

//ex for FR : 14:30
export function useFormattedTime(initialDate) {
  const config = useRuntimeConfig();
  const date = new Date(initialDate);
  return new Intl.DateTimeFormat(config.public["timeZone"]?.toString(), {
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
}

export function useFormattedNote(note) {
  const config = useRuntimeConfig();
  return note
    ? (Math.round(note * 10) / 10).toLocaleString(
        config.public["timeZone"]?.toString(),
        {
          minimumFractionDigits: 1,
        }
      )
    : null;
}

export function useFormattedDescriptionNote(descriptionNote, totalReviews) {
  const text = descriptionNote;
  const regex = /\{{(.*?)\}}/;
  return text?.replace(regex, totalReviews);
}

export function useFormattedAddress(data) {
  const address = data.address;
  const addressSupplement = data.addressSupplement;
  const postalCode = data.postalCode;
  const city = data.city;

  return `${address ? address : ""}${
    addressSupplement ? ", " + addressSupplement : ""
  }${postalCode ? ", " + postalCode : ""}${city ? " " + city : ""}`;
}